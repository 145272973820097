<template>
  <div>
    <div class="ll-box">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('User') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('User List') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          :to="{ name: 'create-user' }"
          v-permission="[174]"
        >
          <span class="text-nowrap">{{ $t('Create User') }}</span>
        </b-button>
      </div>
    </div>
    <!-- Filters -->
    <b-card no-body v-if="isList">
      <b-card-body>
      <div class="row">
          <b-input-group class="col-md-3" style="margin-bottom: 10px;">
            <b-form-input
            v-model="condition.username"
            class="searchInput"
            placeholder="Search by Username"
            />
            <b-input-group-prepend
            is-text
            class="ll-searchBtn"
            @click="searchById"
            >
            <feather-icon
            icon="SearchIcon"
            class="mr-25"
            size="18"
            />
            </b-input-group-prepend>
          </b-input-group>
          <div class="col-md-8"></div>
          <div :title="$t('Organization')" class="col-md-2">
            {{  $t('Organization')  }}
            <v-select
              id="organization"
              v-model="org"
              :options="orgList"
              label="name"
              @input="getOrgId"
              placeholder="Please Select Company"
            ></v-select>
          </div>
          <div :title="$t('Account Type')" class="col-md-2">
            {{  $t('Account Type')  }}
            <v-select
              v-model="accountType"
              :options="accountList"
              label="name"
              @input="selAccount"
              placeholder="Please Select Account Type"
            ></v-select>
          </div>
          <div :title="$t('Role')" class="col-md-3">
            {{ $t('Role') }}
            <v-select
              id="role"
              :options="roleList"
              label="name"
              @input="getRole"
              v-model="condition.roleName"
              placeholder="Please Select Role"
            ></v-select>
          </div>
          <div :title="$t('Creation Date from-to')" class="col-md-3">
            {{ $t('Creation Date from-to') }}
            <b-row>
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="formTime"
                >
                  <flat-pickr
                    id="formTime"
                    v-model="condition.startTime"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
              -
              <b-col>
                <b-form-group
                  class="g-label ll-bform"
                  label=""
                  label-for="toTime"
                >
                  <flat-pickr
                    id="toTime"
                    v-model="condition.endTime"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div :title="$t('Status')" class="col-md-2">
            {{  $t('Status')  }}
            <v-select
              id="status"
              :options="statusList"
              label="statu"
              @input="getStatus"
              v-model="status"
              placeholder="Please Select Status"
            ></v-select>
          </div>
      <div class="ll-btn">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="onClear"
        >
          {{ $t('Clear') }}
        </b-button>
        <b-button variant="info"
          v-b-toggle.filterS
          @click="filterSearch">
          {{ $t('Search') }}
        </b-button>
      </div>
    </div>
    </b-card-body>
    </b-card>
    <!-- Filters sidebar -->

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-if="isList"
      v-permission="[173]"
      :sticky-header="true"
      :busy="isBusy"
      @row-clicked="onDetail1"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(accountType)="row">
        <span>{{ row.value==='UA' ? 'User Account' : 'System Account' }}</span>
      </template>
      <template #cell(isEnabled)="row">
        <b-badge
          :variant="row.value ? 'light-info' : 'light-secondary'"
          class="ll-badge">
          <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-button
          variant="flat-secondary"
          class="btn-icon rounded-circle ll-act"
          :to="{ name: 'user-detail', params: { userId: data.item.userId.toString() } }"
          v-if="false"
        >
          <feather-icon icon="EditIcon"/>
        </b-button>
        <b-button
          variant="flat-secondary"
          class="btn-icon rounded-circle ll-act"
          @click="onDetail(data.item.userId)"
          v-permission="[175]"
        >
          <feather-icon icon="EditIcon"/>
        </b-button>
        <b-dropdown
          variant="link"
          no-caret
          class="ll-act"
          v-if="false"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <!-- <b-dropdown-item :to="{ name: 'user-detail', params: { userId: data.item.userId } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item> -->

          <b-dropdown-item :to="{ name: 'user-detail', params: { userId: data.item.userId } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="delUser">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <!-- <feather-icon
          :id="`${data.item.userId}`"
          icon="EyeIcon"
          size="16"
          class="mx-1"
          @click="$router.push({ name: 'user-detail', params: { userId: data.item.userId }})"
        />
        <b-tooltip
          title="View Details"
          :target="`${data.item.userId}`"
        /> -->
      </template>
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap ll-p" v-if="isList">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- userDetail -->
    <user-detail v-if="isDetail" :userId="userId"></user-detail>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  // BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb, BBadge,
  BBreadcrumbItem, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import userDetail from './components/userDetail.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    userDetail,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    // BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    flatPickr,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdown,
    BDropdownItem,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'username', label: this.$t('Username'), sortable: true },
        // { key: 'fullName', label: 'Full name', sortable: true },
        { key: 'accountType', label: this.$t('Account Type'), sortable: true },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'role', label: this.$t('Role'), sortable: true },
        { key: 'organizationName', label: this.$t('Organization'), sortable: true },
        { key: 'createdAt', label: this.$t('Creation Date'), sortable: true },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      searchTerm: '',
      roleList: [],
      orgList: [],
      statusList: [
        { id: false, statu: 'Inactive' },
        { id: true, statu: 'Active' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '50',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: '',
        username: '',
      },
      userNList: '',
      filterBg: {
        '--background': '#fff'
      },
      org: null,
      status: null,
      isList: true,
      isDetail: false,
      userId: null,
      accountType: null,
      accountList: [
        { name: 'User Account', key: 'UA' },
        { name: 'System Account', key: 'SA' },
      ],
      isBusy: true,
    }
  },
  computed: {},
  watch: {
    'org'(val) {
      this.setBg(val)
    },
    'condition.roleName'(val) {
      this.setBg(val)
    },
    'condition.startTime'(val) {
      this.setBg(val)
    },
    'condition.endTime'(val) {
      this.setBg(val)
    },
    'status'(val) {
      this.setBg(val)
    },
  },
  created() {
    // this.getList()
  },
  mounted() {
    this.getOrganization()
    this.getRoles()
    this.ability()
    this.ability1()
  },
  methods: {
    onDetail(userId) {
      this.isList = false
      this.isDetail = true
      this.userId = userId.toString()
    },
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'LMD Customer'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.condition.organizationId = userData.orgId
        this.getList()
      } else {
        this.condition.username = null
        this.getList()
      }
    },
    ability1() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'seller'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        const id = userData.userId
        this.$router.push({ name: 'user-account', params: {userId: id.toString()} })
      }
    },
    // filter图标背景颜色设置
    setBg(val) {
      if (val !== null && val !== '') {
        this.filterBg= {
          '--background': '#ff9f43'
        }
      } else {
        this.filterBg= {
          '--background': '#fff'
        }
      }
    },
    // 首次查询
    getList() {
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('ss', res.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onSearch() {
      // console.log('onSearch')
    },
    onClear() {
      // this.condition = {}
      this.org = null
      this.condition.roleName = null
      this.condition.startTime = null
      this.condition.endTime = null
      this.status = null
      this.accountType = null
    },
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    // 根据username查询
    searchById() {
      this.isList = true
      this.isDetail = false
      this.condition.username = this.condition.username.trim()
      this.getList()
    },
    // 根据username查询-b
    handleSearch(searching) {
      this.condition.trackingIDs = searching.split(/[(\r\n)\r\n]+/)
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    // 查询组织信息
    getOrganization() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        // console.log(res.data.data)
        this.orgList = res.data.data
      })
    },
    // 获取组织ID
    getOrgId(id) {
      this.condition.organizationId = id.id
      this.org = id.name
    },
    // 通过组织ID查询角色信息
    getRoles() {
      this.$http.get('/admin/settings/role/roleList').then(res => {
        this.roleList = res.data.data
      })
    },
    getRole(d) {
      this.condition.roleName = d ? d.name : null
    },
    getStatus(val) {
      this.condition.status = val.id
      this.status = val.statu
    },
    // 删除用户
    delUser() {

    },
    selAccount(a) {
      this.condition.accountType = a.key
    },
    onDetail1(item){
      this.onDetail(item.userId)
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  bottom: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-act{
  height: 15px;
  width: 15px;
}
.btn .feather{
  position: relative;
  top: -8px;
  left: -5px;
}
.ll-outline> div{
  background: var(--background);
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
/* [dir] .dropdown-menu[x-placement=bottom-start]{
  margin-top: unset;
} */
</style>
